<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <div class="content">
          <h3 class="cent">{{ data.title }}</h3>
          <p class="cent">来源：{{ data.source }}&nbsp;|&nbsp;作者： &nbsp;{{ data.author }}</p>
          <p class="cent">时间：{{ data.time }}</p>

          <div style="cursor: default" v-html="data.content"></div>

        </div>
        <last-and-next v-on:toArticle="toArticle" :last="lastArticle" :next="nextArticle"></last-and-next>
      </div>
      <div slot="rightDiv">
        <card ref="card">
          <div slot="heard" class="search">
            <input placeholder="搜索">
            <i><img src="../../assets/image/icon/search.png"></i>
          </div>
        </card>
        <img src="../../assets/image/web.png">
      </div>
    </left-and-right>
  </div>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";
import card from "@/components/public/card";
import lastAndNext from "@/components/public/lastAndNext";

export default {
  name: "literatureDetails",
  components: {
    leftAndRight,
    card,
    lastAndNext
  },
  data() {
    return {
      id: this.$route.query.id,
      data: {},
      lastArticle: {
        name: '',
        id: null
      },
      nextArticle: {
        name: '',
        id: null
      },
      card: [
        {
          index: 0,
          title: '热门文章',
          content: [
            {
              index: 0,
              text: '建立时间简史噶十几斤啊手机号打电话不得不菜市场'
            },
            {
              index: 1,
              text: '建立时间简史噶十几斤啊手机号打电话不得不菜市场'
            },
            {
              index: 2,
              text: '建立时间简史噶十几斤啊手机号打电话不得不菜市场'
            }
          ]
        }
      ],
    }
  },
  mounted() {
    this.getArticle()
  },
  methods: {
    getArticle() {
      this.$http.get('/safe/getArticle', {
        params: {
          id: this.id
        }
      }).then((res) => {
        this.data = res.data
        this.data.time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", new Date(res.data.time))
        if (res.data.lastArticle !== null) {
          this.lastArticle.name = res.data.lastArticle.title
          this.lastArticle.id = res.data.lastArticle.id
        } else {
          this.lastArticle.name = null
        }
        if (res.data.nextArticle !== null) {
          this.nextArticle.name = res.data.nextArticle.title
          this.nextArticle.id = res.data.nextArticle.id
        } else {
          this.nextArticle.name = null
        }

      })

      const list = ["协会大事记"]

      for (let index in list) {
        const ths = this
        this.$http.get('/safe/getIndexInformation', {
          params: {
            name: list[index],
          }
        }).then((res) => {
          let forms = res.data

          for (let i in forms) {
            let pic = forms[i].pic
            if (pic != null) {
              forms[i].pic = this.$constContent.imgUrl + pic
            }
            let time = forms[i].time;
            let date = new Date(time);
            res.data[i].time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
          }
          let Cards = []
          let Card = {}
          Card.title = list[index]
          Card.id = 0
          Card.content = res.data
          Cards.push(Card)
          ths.$nextTick(() => {

            ths.$refs.card.init(Cards)
          })
        })
      }
    },
    toArticle(e) {
      this.id = e
      this.getArticle()
    }

  }
}
</script>

<style scoped>
.content p {
  color: #666666;
  font-size: 14px;
}

.cent {
  text-align: center;
}

.text {
  margin: 20px 0;
}

.search {
  width: 100%;
  height: 40px;
}

.search input {
  height: 38px;
  width: calc(100% - 20px);
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid #dfdfdf;
}

.search i img {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 12px;
  right: 12px;
}

img {
  width: 100%;
}
</style>
